import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import * as styles from './Job.module.scss';
import ReCAPTCHA from "react-google-recaptcha";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { Applicant, Content, RecruitmentWorkflow } from '../../../../Classes/Recruitment';
import Input from '../../../../components/Input/input';
import { API_SubmitApplicant, API_ViewJob } from '../../../../Services/ApiRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Fetch } from '../../../../Services/Fetch';

interface Props {
  job: RecruitmentWorkflow;
  Preview?: boolean;
}

const Job: React.FC<Props> = ({ job, Preview }) => {
  const [captcha, setCaptcha] = useState<string>("");
  const [applicant, setApplicant] = useState<Applicant>({} as Applicant);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [shareURL, setShareURL] = useState<string>("");
  const [file, setFile] = useState<string>("");

  useEffect(() => {
    if (job.ID && !Preview)
      View();
  }, [job])

  const View = () => {
    Fetch(`${API_ViewJob}`, job.ID).then((success: boolean) => {
      if (success)
        setShareURL(`https://primehr.co.uk/Job?ID=${window.btoa(job.ID.toString())}`);
    })
  }

  const Save = (e: any) => {
    setSubmitting(true);
    e.preventDefault();
    var input: any = document.querySelector('input[type="file"]');
    var data = new FormData()
    data.append('file', input.files[0])
    data.append('Forename', applicant.Forename);
    data.append('Surname', applicant.Surname);
    data.append('Email', applicant.Email);
    data.append('Phone', applicant.Phone);
    data.append('Applied', new Date().toISOString());
    data.append('AdvertID', job.ID.toString());

    fetch(`${API_SubmitApplicant}`, {
      method: 'POST',
      body: data
    }).then((Response) => {
      if (Response.ok)
        return Response.json();
      throw Response;
    }).then((Response: Applicant) => {
      setSubmitting(false);
      setApplicant(Response);
    });
  }

  const GetFileName = (e: any) => {
    var fullPath = e.target.value;
    if (fullPath) {
      var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
      var filename = fullPath.substring(startIndex);
      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
      }
      setFile(filename);
    } else {
      setFile("");
    }
  }

  return (
    <>
      {Preview || !job.ID ? <></> : <>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>PrimeHR :: Job :: {job.JobTitle}</title><meta charSet='utf-8' />
          <meta property="og:description" content="" />
          <meta property="og:title" content="PrimeHR :: Apply Now!" />
          <meta property="og:site_name" content="PrimeHR" />
          <meta property="og:url" content={shareURL} />
        </Helmet>

        <Banner />

        <Breadcrumb Trail={[
          { To: "/Blog", Text: "Blog" },
          { To: "", Text: job.JobTitle }
        ]} />
      </>}

      <div className={styles.Section}>
        <div className={styles.SectionInner}>
          <h1>{job.JobTitle}</h1>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </div>

          <p>£{job.SalaryMin} - £{job.SalaryMax}</p>
          <p>{job.Type}, {job.Hours} Hours Per Week, {job.Department}</p>

          {
            job.AdvertJSON ? job.AdvertJSON.map((Data: Content) => {
              return <>
                <h2>{Data.Title}</h2>
                <pre>{Data.Content}</pre>
              </>
            }) : <></>
          }
        </div>
        <div className={styles.SectionSide}>
          {Preview ? <></> : <>
            <form className={styles.SectionSideApply} onSubmit={(e) => Save(e)}>
              <h1>Apply Now</h1>

              {applicant.ID ? <div className={styles.Message}>
                <i>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </i>
                <h2>Successfully Submitted</h2>
                <p>You have successfully applied for this role. You may be contacted soon!</p>
              </div> : <>
                <Input Width="half" OnChange={v => setApplicant({ ...applicant, Forename: v })} Placeholder="..." Type="text" Label="Forename" Required Value={applicant.Forename} />
                <Input Width="half" OnChange={v => setApplicant({ ...applicant, Surname: v })} Placeholder="..." Type="text" Label="Surname" Required Value={applicant.Surname} />
                <Input OnChange={v => setApplicant({ ...applicant, Email: v })} Placeholder="..." Type="email" Label="Email" Required Value={applicant.Email} />
                <Input OnChange={v => setApplicant({ ...applicant, Phone: v })} Placeholder="..." Type="text" Label="Phone" Required Value={applicant.Phone} />
                <div className={styles.File}>
                  <input onChange={(e) => GetFileName(e)} type="file" accept='.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' />
                  <span>Upload CV</span>
                </div>
                {file ? <small>{file}</small> : ""}
                <div className="capcha">
                  <ReCAPTCHA
                    onExpired={() => { setCaptcha("") }}
                    onErrored={() => { setCaptcha("") }}
                    sitekey="6Lf-XiQaAAAAAI5mHpzBUc6vtyvEWTS17PLcGnWT" onChange={e => { setCaptcha(e); }} />
                </div>
                <button disabled={submitting || (!captcha || !applicant.Forename || !applicant.Surname || !applicant.Email || !applicant.Phone)} type="submit">
                  {submitting ? <>
                    <i>
                      <FontAwesomeIcon icon={faSpinner} spin={true} />
                    </i>
                    Submitting...
                  </> : <>
                    Apply
                  </>}
                </button>
              </>}
            </form>
          </>}
        </div>
      </div>
    </>
  );
};

export default Job;