// extracted by mini-css-extract-plugin
export var Download = "Job-module--Download--f310f";
export var File = "Job-module--File--7754a";
export var Green = "Job-module--Green--3e0d7";
export var Message = "Job-module--Message--30295";
export var Section = "Job-module--Section--da5ba";
export var SectionImage = "Job-module--SectionImage--0bf8b";
export var SectionInner = "Job-module--SectionInner--07083";
export var SectionSide = "Job-module--SectionSide--5dba4";
export var SectionSideApply = "Job-module--SectionSideApply--b5ea5";
export var Social = "Job-module--Social--2d215";