import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { RecruitmentWorkflow } from '../Classes/Recruitment';
import Layout from '../components/Layout/layout';
import { API_GetJobAdvert } from '../Services/ApiRoutes';
import { Fetch } from '../Services/Fetch';
import Job from '../Views/Website/Jobs/Job/Job';

const JobPage: React.FC = (props: any) => {
  const [job, setJob] = useState<RecruitmentWorkflow>({} as RecruitmentWorkflow);

  useEffect(() => {
    GetJob()
  }, [])

  const GetJob = async () => {
    let ID = location.search.replace("?", "").split("&").find(d => d.toLowerCase().startsWith("id=")).split("=")[1];

    if (ID)
      await Fetch(`${API_GetJobAdvert}`, window.atob(ID)).then((Advert: RecruitmentWorkflow) => {
        if (Advert.ID) {
          setJob({ ...Advert, AdvertJSON: JSON.parse(Advert.Advert) });
        } else {
          navigate(`/Jobs`)
        }
      })
  }

  return (
    <Layout>
      <Job job={job} />
    </Layout>
  );
};

export default JobPage;
